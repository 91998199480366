<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-lg-3 col-md-12 mb-3">
          <div
            class="nav flex-column nav-pills me-3"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              class="nav-link active"
              id="v-pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-home"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              Meus dados
            </button>
            <!-- <button
              class="nav-link"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              Profile
            </button>
            <button
              class="nav-link"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              Messages
            </button>
            <button
              class="nav-link"
              id="v-pills-settings-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-settings"
              type="button"
              role="tab"
              aria-controls="v-pills-settings"
              aria-selected="false"
            >
              Settings
            </button> -->
          </div>
        </div>
        <div class="col-lg-9 col-md-12">
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div class="card">
                <div class="card-body">
                  <form @submit.prevent="save">
                    <h6 class="card-title">Meus dados</h6>

                    <div class="d-flex mb-3">
                      <figure class="me-3">
                        <img
                          width="100"
                          class="rounded"
                          src="../../assets/media/image/user/women_avatar1.jpg"
                          alt="..."
                        />
                      </figure>
                      <div>
                        <p>{{ name.value }}</p>
                        <!-- <button class="btn btn-outline-primary me-2">
                          Change Avatar
                        </button>
                        <button class="btn btn-outline-danger">
                          Remove Avatar
                        </button> -->
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label>Nome</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{'is-invalid': !name.valid}"
                            v-model.trim="name.value"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{'is-invalid': !email.valid}"
                            v-model.trim="email.value"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col">
                        <div class="form-group">
                          <label>Senha atual</label>
                          <input type="password" class="form-control" :class="{'is-invalid': !oldPassword.valid}" v-model.trim="oldPassword.value" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Nova senha</label>
                          <input type="password" class="form-control" :class="{'is-invalid': !newPassword.valid}" v-model.trim="newPassword.value" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label>Repita a nova senha</label>
                          <input type="password" class="form-control" :class="{'is-invalid': !newPassword2.valid}" v-model.trim="newPassword2.value" />
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col">
                        <button class="btn btn-primary">Salvar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      name: {
        value: null,
        valid: true
      },
      email: {
        value: null,
        valid: true
      },
      oldPassword: {
        value: null,
        valid: true
      },
      newPassword: {
        value: null,
        valid: true
      },
      newPassword2: {
        value: null,
        valid: true
      },
      validForm: false
    }
  },
  methods: {
    ...mapActions(['updateUserName']),
    validateForm() {
      this.name.valid = true;
      this.email.valid = true;
      this.oldPassword.valid = true;
      this.newPassword.valid = true;
      this.newPassword2.valid = true;
      this.validForm = true;

      // Test name
      if (this.name.value === '') {
        this.name.valid = false;
        this.validForm = false;
        this.$toast.add({severity:'error', summary: 'O nome não pode ser vazio.'});
      }

      // Test email
      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email.value)) {
        this.email.valid = false;
        this.validForm = false;
        this.$toast.add({severity:'error', summary: 'E-mail inválido.'});
      }

      // Test passwords
      if (this.oldPassword.value) {
        if (!this.newPassword.value) {
          this.newPassword.valid = false;
          this.validForm = false;
          this.$toast.add({severity:'error', summary: 'A nova senha não pode ser vazia.'});
        }

        if (this.newPassword.value != this.newPassword2.value) {
          this.newPassword.valid = false;
          this.newPassword2.valid = false;
          this.validForm = false;
          this.$toast.add({severity:'error', summary: 'As senhas não conferem.'});
        }
      }
    },
    async save() {
      this.validateForm();

      if (this.validForm) {

        const data = {
          name: this.name.value,
          email: this.email.value
        };

        if (this.newPassword.value) {
          data.oldPassword = this.oldPassword.value;
          data.newPassword = this.newPassword.value;
        }

        const url = `${this.apiEndpoint}/me`;

        try {
          await axios.post(url, data);

          this.$toast.add({severity:'success', summary: 'Dados atualizados.'});
          this.updateUserName(this.name.value);
          this.oldPassword.value = '';
          this.newPassword.value = '';
          this.newPassword2.value = '';
        } catch (err) {
          switch (err.response.data.errorCode) {
            case 1:
              this.$toast.add({severity:'error', summary: 'Senha errada.', life: 3000});
              this.oldPassword.valid = false;
              break;

            case 2:
              this.$toast.add({severity:'error', summary: 'E-mail já em uso por outro usuário.'});
              this.email.valid = false;
              break;

            default:
              this.$toast.add({severity:'error', summary: 'Houve um erro ao salvar os dados.'});
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['apiEndpoint'])
  },
  async created() {
    const url = `${this.apiEndpoint}/me`;
    const me = await axios.get(url);

    this.name.value = me.data.name;
    this.email.value = me.data.email;
  }
}
</script>

<style scoped>
.nav-link {
  text-align: left;
}
</style>